@import "variables";
//  图标
// @import './iconfont/demo.css';
// .icon {
//     display: inline-block;
//     vertical-align: middle;
//     width: 18px;
//     min-height: 18px;
//     background: url('../assets/sprite.png') no-repeat;
// }
// .sprite {
//     background: url('../assets/sprite.png') no-repeat;
// }
// .icon.icon-user {
//     background-position: 0 -321px;
// }
// .icon.icon-pwd {
//     background-position: 2px -465px;
// }
// .log-out {
//     cursor: pointer;
//     background-position: 0 -2077px;
// }
// .roler {
//     background-position: 0 -350px;
// }
// .account {
//     background-position: 0 -254px;
// }
.f-fl {
    width: 280px;
    height: 50px;
    margin-top: 5px;
    background: url('../assets/logo2.png') center no-repeat;
    background-size: cover;
}


.ant-table-thead{
    background:rgba(245,245,245,1);
}


// MENU css
.ant-menu-inline {
    border: none;
}
// .ant-menu-inline > .ant-menu-submenu > div.ant-menu-submenu-title {
//     height: 50px;
//     line-height: 50px;
//     margin: 0;
//     padding-left: 36px;
// }
// .ant-menu-sub.ant-menu-inline > li.ant-menu-item {
//     height: 50px;
//     line-height: 50px;
//     margin: 0;
//     &::after{
//         content: none;
//     }
// }
// .ant-menu-submenu-active .ant-menu-submenu-title {
//     background-color: #edeff1;
// }
// .ant-menu-submenu-open .ant-menu-submenu-title {
//     color: #fff;
//     background-color: #009587;
//     .ant-menu-submenu-arrow::after, .ant-menu-submenu-arrow::before {
//         background: #fff !important;
//     }
// }
// .ant-menu-submenu-active.ant-menu-submenu-open .ant-menu-submenu-title {
//     color: #fff;
//     background-color: #009587;
//     .ant-menu-submenu-arrow::after, .ant-menu-submenu-arrow::before {
//         background: #fff !important;
//     }
// }

// table滚动条样式
// .my-table .ant-table-body {
//     &::-webkit-scrollbar {
//         width: 6px;
//         height: 6px;
//         background-color: #f1f1f1;
//     }
//     &::-webkit-scrollbar-thumb {
//         border-radius: 10px;
//         background-color: #c1c1c1;
//     }
//     &::-webkit-scrollbar-track {
//         border-radius: 20px;
//     }
// }
.my-table {
    .my-table-btn {
        font-size:14px;
        line-height:20px;
        color: $blue;
        cursor: pointer;
    }
    .my-table-divider {
        margin-top: 6px;
        background: $blue;
    }
    .my-table-btn-box {
        padding: 0 10px;
    }
}

// 次级页面的头部
.secondary-header{
    border-bottom: 1px solid #E1E1E1;
    padding: 10px 0 30px;
}

.input-captcha {
    .ant-input-group-addon:last-of-type {
        position: relative;
        width: 100px;
        height: 36px;
    }
    .react-captcha {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
