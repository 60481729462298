.box{
    width: 94%;
    margin: 2% auto;
    color: #000;

    .title{
        font-size: 2em;
        font-weight: 600;
        text-align: center ;
    }
    .time{
        text-align: right;
    }
    .weight{
        font-weight: 900
    }
    p{
        line-height: 28px;
    }
}
