@import "../../style/util";
@import "../../style/variables";

$home: home;

.#{$home} {
    .ant-card-body {
        padding-bottom: 80px;
        background: url("../../assets/index_print_goods.png") right bottom no-repeat;
        background-size: 220px 220px;
        border-radius: 4px;
    }

    .ant-typography {
        margin-bottom: 0;
        min-width: 270px;
        max-width: 270px;
    }

    .top {
        position: relative;

        img {
            @include hw(200px);
        }

        .text {
            @include positionCenter;
            @include hw(56px, 744px);
            @include sc(40px);
        }
    }

    .content {
        margin-top: 20px;

        .ant-col {
            .ant-row {
                &:first-child {
                    .ant-card-body {
                        padding-bottom: 80px;
                        background: url("../../assets/index_print_number.png") right bottom no-repeat;
                    }
                }
            }
        }

        .tipsList {
            padding: 30px;
            background-color: #fff;
            border-radius: 4px;
        }
    }

    &-title {
        position: relative;
        padding-left: 10px;
        @include sc(16px, #333);
        font-weight: bold;

        &:before {
            position: absolute;
            left: -5px;
            top: 3px;
            content: " ";
            height: 18px;
            width: 5px;
            background-color: $primaryColor;
        }
    }

    &-statistics {
        .total {
            position: relative;
            @include sc(40px, #000);
            line-height: 40px;
            font-weight: bold;

            i {
                position: absolute;
                top: 5px;
                margin-left: 20px;
                @include sc(14px, #333);
                font-style: normal;
            }
        }
    }
}