.userinfo-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 23px;
    color: rgba(51, 51, 51, 1);
}

.userInfo-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 23px;
    color: rgba(51, 51, 51, 1);
    margin-top: 24px;

    .user-info {
        margin-left: 90px;
    }
}

.user-box {
    width: 90%;
    margin: auto;
    margin-top: 10px;
    border: 1px solid rgb(224, 224, 224);
}