/**
 * @author: EthanWong
 * @date: 2020/06/11
 */
@mixin hw($height: auto, $width: 100%) {
    width: $width;
    height: $height;
}

@mixin positionCenter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

@mixin sc($font-size: 12px, $color: #fff) {
    font-size: $font-size;
    color: $color;
}

@mixin flexBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
